import React from 'react';

import Page from 'components/Page';
import Intro from 'components/Intro';

const metaTags = {
  title: 'webkid - 404',
  author: 'Webkid',
  twitterUsername: '@webk1d',
  description: "Oh noes! This site doesn't exist.",
  siteUrl: 'https://webkid.io',
  robots: 'noindex, nofollow',
  image: 'https://webkid.io/content/images/social.png',
};

const Home = () => {
  return (
    <Page metaTags={metaTags}>
      <Intro title="page not found." />
    </Page>
  );
};

export default Home;
